import Footer from "@/components/common/Footer";
import SnapScrollContainer from "@/components/common/SnapScrollContainer";
import BaseSeo from "@/components/seo/BaseSeo";
import PageContent from "@/components/PageContent";
import { useMediaQuery, useTheme } from "@mui/material";
import { Page, PageSection } from "interfaces/api_interfaces";
import { GetStaticProps, InferGetStaticPropsType } from "next";
import { useLocalization } from "providers/LocalizationProvider";
import { publicApi } from "api/content-api-client";
import { REVALIDATE_TIME } from "utils/constants";
import { useEffect } from "react";
import { useGlobalState } from "providers/GlobalProvider";
import { NavColor } from "@/components/common/Navbar";

const HomePage = ({ page }: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { toLocalizedString } = useLocalization();
  const { setColors } = useGlobalState();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    const {
      invert_menu_colors,
      menu_background_white,
      invert_scroll_indicator_colors,
    } = page;

    const menuColor = invert_menu_colors ? NavColor.Dark : NavColor.Light;
    const scrollColor = invert_scroll_indicator_colors
      ? NavColor.Dark
      : NavColor.Light;
    const menuBackground = menu_background_white
      ? NavColor.Dark
      : NavColor.Light;

    setColors({
      nav: menuColor,
      navBackground: menuBackground,
      scroll: scrollColor,
    });
  }, [page, setColors]);

  return (
    <>
      <BaseSeo
        title={toLocalizedString(page.name)}
        description={toLocalizedString(page.seo_description)}
        image={{ url: page.seo_image }}
        ogUrl={page.meta_url_handle}
      />
      <main>
        <SnapScrollContainer key={`index-snap-container`}>
          {page.sections.map((template: PageSection, i: number) => (
            <PageContent
              key={template.id}
              template={template}
              showFooter={page.sections.length - 1 === i && !smallScreen}
            />
          ))}
          {smallScreen && <Footer />}
        </SnapScrollContainer>
      </main>
    </>
  );
};

// Fetch home page only
export const getStaticProps: GetStaticProps<{ page: Page }> = async () => {
  const { data: pageData } = await publicApi.get<Page>("/pages/home");

  return {
    props: { page: pageData },
    revalidate: REVALIDATE_TIME,
  };
};

export default HomePage;
